import {Button, Descriptions, Flex, Form, Input, notification, Table, TableProps, Tabs, Tag, Tooltip} from "antd";
import {useLocation, useNavigate} from "react-router";
import {IoMdArrowRoundBack} from "react-icons/io";
import React, {useEffect, useState} from "react";
import {IPlanAfterLessons, IPlanDetails} from "../model/IPlanDetails";
import {downloadFileWithParams, getRequestWithParams, putRequestWithParamsAndBody} from "../../../api/httpRequest";
import "./styles/styles.less"
import {FaCheck, FaFilePdf} from "react-icons/fa6";
import dayjs from "dayjs";
import {CgClose} from "react-icons/cg";
import {AiFillEdit} from "react-icons/ai";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import PlansLessonsComponent from "./components/PlansLessonsComponent";


const PlanDetail: React.FC = () => {

    const location = useLocation()
    const navigate = useNavigate()
    const [api, contextHolder] = notification.useNotification();
    const [details, setDetails] = useState<IPlanDetails>()
    const [form] = Form.useForm();
    const [afterLessons, setAfterLessons] = useState<IPlanAfterLessons[]>([])
    const [sendingAfterLessons, setSendingAfterLessons] = useState<boolean>(false)
    const [signingPlan, setSigningPlan] = useState<boolean>(false)
    const [showSaveButton, setShowSaveButton] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [editableAffterLesson, setEditableAffterLesson] = useState<number>()
    const [force, setForce] = useState(false)
    const {xl} = useBreakpoint()

    const afterLessonTable: TableProps["columns"] = [
        {
            title: '#',
            key: 'num',
            width: "3%",
            render: (value, record, index) => {
                return index + 1
            }
        },
        {
            title: 'Описание',
            key: 'desc',
            render: (value, record, index) => {
                if (editableAffterLesson === index) {
                    return <Form
                        form={form}
                        onFinish={() => {
                            setAfterLessons(afterLessons.map((lesson, lessonIndex) => {
                                if (lessonIndex === editableAffterLesson) {
                                    return {
                                        discription: form.getFieldValue("inputText")
                                    }
                                }
                                return lesson
                            }))
                            setEditableAffterLesson(-1)
                        }}>
                        <Form.Item name="inputText" initialValue={record.discription}>
                            <Input autoFocus/>
                        </Form.Item>
                    </Form>
                }
                return record.discription
            }
        },
        {
            title: 'Действия',
            key: 'desc',
            width: "5%",
            render: (value, record, index) => (
                <Flex gap={8}>
                    {editableAffterLesson !== index ?
                        <Tooltip title="Редактировать">
                            <Button size={"small"}
                                    disabled={!details?.editEnable}
                                    icon={<AiFillEdit/>} onClick={() => {
                                setEditableAffterLesson(index)
                            }}/>
                        </Tooltip>
                        :
                        <Tooltip title="Сохранить">
                            <Button
                                size={"small"}
                                disabled={!details?.editEnable}
                                style={{backgroundColor: "#1677ff", color: "white"}}
                                icon={<FaCheck/>}
                                onClick={() => {
                                    form.submit()
                                    setShowSaveButton(true)
                                }}/>
                        </Tooltip>
                    }
                    <Tooltip title="Удалить">
                        <Button
                            size={"small"}
                            disabled={!details?.editEnable}
                            icon={<CgClose/>}
                            onClick={() => {
                                setEditableAffterLesson(-1)
                                setAfterLessons(afterLessons.filter((lesson, lessonIndex) => {
                                    return lessonIndex !== index
                                }))
                                setShowSaveButton(true)
                            }}/>
                    </Tooltip>
                </Flex>
            )
        }
    ]

    const signatureTab: TableProps['columns'] = [
        {
            title: 'Подписант',
            key: 'signatory',
            dataIndex: "signatory"
        },
        {
            title: 'Дата',
            key: 'date',
            dataIndex: "date",
            render: value => (
                <span>{`${dayjs(value).format('DD MMMM YYYY')} г.`}</span>
            )
        },
        {
            title: 'Статус',
            key: 'approval',
            dataIndex: "approval",
            render: (value) => (
                <div>{value ? "Утверждено" : "Ознакомлено"}</div>
            ),
        },
    ]
    const sendAfterLessons = () => {
        setSendingAfterLessons(true)
        const request = async () => {
            putRequestWithParamsAndBody(
                "/api/v1/plans",
                {planId: details?.planId},
                {afterLessons: afterLessons}
            ).then(response => {
                    setSendingAfterLessons(false)
                    api.success({
                        message: 'Внеучебная нагрузка сохранена',
                        duration: 2
                    });
                    setShowSaveButton(false)
                }
            ).catch(error => {

                setSendingAfterLessons(false)
                api.error({
                    message: 'Ошибка сохранения внеучебной нагрузки',
                    duration: 2
                });
            })
        }
        request()
    };

    const tabsConfig = [
        {
            label: `Учебная нагрузка`,
            key: "1",
            children:
                <PlansLessonsComponent details={details}/>,
        },
        {
            label: `Внеучебная нагрузка`,
            key: "2",
            children:
                <Flex style={{width: "100%"}} vertical gap={8}>

                    <Table
                        bordered
                        pagination={false}
                        columns={afterLessonTable}
                        style={{width: "100%"}}
                        className={"plansTable"}
                        dataSource={afterLessons}
                    />
                    <Button
                        type={"primary"}
                        disabled={!details?.editEnable}
                        style={{width: 100}}
                        onClick={() => {
                            setAfterLessons([
                                ...afterLessons,
                                {
                                    discription: ""
                                }
                            ])
                            setEditableAffterLesson(afterLessons.length)
                            setShowSaveButton(true)
                        }}
                    >
                        Добавить
                    </Button>
                </Flex>,
        },
        {
            label: `Подписание`,
            key: "3",
            children:
                <Flex style={{width: "100%"}}>
                    <Table
                        bordered
                        pagination={false}
                        columns={signatureTab}
                        style={{width: "100%"}}
                        className={"plansTable"}
                        dataSource={details?.signatures}
                    />
                </Flex>,
        },
    ]

    const fetchDetails = () => {
        setIsLoading(true)
        getRequestWithParams("/api/v1/plans", {planId: location.state.planId})
            .then(response => {
                setDetails(response.data)
                setAfterLessons(response.data.afterLessons)
            })
        setIsLoading(false)
    }

    useEffect(() => {
        fetchDetails()
    }, [force]);

    const printPlan = () => {
        downloadFileWithParams("/api/v1/plans/print", {planId: location.state.planId})
            .then(response => {

                const href = URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `Индивидуальный_план_${details?.number}.pdf`); //or any other extension
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
    };

    const signPlan = () => {
        setSigningPlan(true)
        const request = async () => putRequestWithParamsAndBody(
            "/api/v1/plans",
            {planId: details?.planId},
            {signatureDate: dayjs().toISOString()}
        ).then(response => {
            api.success({
                message: 'План успешно подписан',
                duration: 2
            });
            setSigningPlan(false)
            setForce(!force)
        }).catch(error => {
            api.error({
                message: 'Ошибка подписания плана',
                duration: 2
            });
            setSigningPlan(false)
        })
        request()
    };

    const descriptionItems = [

        {
            key: 'employee',
            label: 'Предоставление плана',
            children: <div>{details?.employee}</div>,
        },
        {
            key: 'department',
            label: 'Подразделение',
            children: <div>{details?.department}</div>,
        },
        {
            key: 'employment',
            label: 'Вид занятости',
            children: <div>{details?.employment}</div>,
        },
        {
            key: 'position',
            label: 'Должность',
            children: <div>{details?.position}</div>,
        },
        {
            key: 'tarif',
            label: 'Вид начисления',
            children: <div>{details?.tarif}</div>,
        },
        {
            key: 'rate',
            label: 'Ставка',
            children: <div>{details?.rate}</div>,
        }
    ]

    const calculateStatusColor = () => {

        if (details?.status === "На подписании у меня") {
            return "orange"
        }
        if (details?.status === "Утвержден") {
            return "green"
        }

        return "processing";
    };
    return (
        <Flex vertical align={"flex-start"} style={{width: "100%"}} gap={8}>
            {contextHolder}
            <Flex style={{width: "100%"}} justify={"space-between"} vertical={!xl} align={xl ? "center" : "flex-start"}>
                {location.state.taskId !== null ?
                    <Button
                        onClick={() => navigate("/tasks",{state: {taskId: location.state.taskId}})}
                        icon={<IoMdArrowRoundBack/>}
                        type={"text"}>Вернуться к задаче
                    </Button>
                    :

                    <Button
                        onClick={() => navigate("/plans")}
                        icon={<IoMdArrowRoundBack/>}
                        type={"text"}>К списку индивидуальных планов
                    </Button>
                }
                <Flex gap={8}>
                    <Button
                        onClick={printPlan} icon={<FaFilePdf/>}>Печать
                    </Button>
                    <Button
                        disabled={!details?.editEnable || !showSaveButton}
                        type={"primary"}
                        onClick={sendAfterLessons}
                        loading={sendingAfterLessons}
                    >Сохранить</Button>
                    <Button
                        type={"primary"}
                        loading={signingPlan}
                        onClick={signPlan}
                        disabled={!details?.approveEnable}
                    >Подписать
                    </Button>
                </Flex>
            </Flex>
            <Descriptions
                size={"small"}
                bordered={xl}
                className={"planDetails"}
                title={(
                    <Flex gap={8} align={"center"}>
                        <Flex>
                            {details?.planName}
                        </Flex>
                        <Tag color={calculateStatusColor()}>
                            {details?.status}
                        </Tag>
                    </Flex>
                )}
                items={descriptionItems}/>
            <Flex className={"planDetails"}>
                <Tabs style={{width: "100%"}}
                      defaultActiveKey="1"
                      items={tabsConfig}
                />
            </Flex>
        </Flex>
    )
}

export default PlanDetail